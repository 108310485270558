<template>
  <default-layout>
    <div
      class="spainhn-solid-red-background d-flex"
      style="min-height: 5vh; width: 100%"
    >
      <div class="d-flex ps-5 my-auto container">
        <p
          class="my-auto pb-0 mb-0 spainhn-small-text"
          role="button"
          @click="goToHome"
        >
          Spain Heritage Network >>
        </p>
        <p class="my-auto pb-0 mb-0 ms-2 spainhn-small-text">
          {{ $t("default.SERVICE_PROVIDERS_REGISTER") }}
        </p>
      </div>
    </div>

    <div
      class="container-fluid px-md-1 px-lg-4 px-1 py-5"
      :style="{
        padding: '0 0',
        backgroundImage: `url(${require('@/assets/home-bg.jpg')})`,
        backgroundSize: 'cover',
        'background-repeat': 'no-repeat',
        'background-position': 'right',
        'min-height': '80vh',
      }"
    >
      <div class="container bg-white shadow px-sm-0 px-md-5 pt-3">
        <div class="row">
          <div class="col-12">
            <p class="spainhn-subtitle">Registro como promotor de servicios</p>
          </div>
        </div>
        <form
          class="pt-1 pb-5 mb-5"
          @submit.prevent="validarRegistro"
        >
          <div class="row">
            <div class="col-12 spainhn-divider mb-2 text-start">
              <p class="fw-bold">Datos de usuario</p>
            </div>

            <div class="col-12 col-md-6">
              <mdb-input
                label="Persona de contacto*"
                type="text"
                name="persona-contacto"
                v-model="dataForm.nombreContacto"
                required
                outline
                invalidFeedback="Campo obligatorio *"
              />
            </div>

            <div class="col-md-6 col-12">
              <mdb-input
                label="Email de usuario *"
                type="email"
                name="email-usuario"
                v-model="dataForm.emailContacto"
                required
                outline
                invalidFeedback="Campo obligatorio *"
              />
            </div>

            <div class="col-12 col-md-6">
              <mdb-input
                label="Teléfono*"
                type="tel"
                v-model="dataForm.telefonoContacto"
                required
                outline
              ></mdb-input>
            </div>

            <div class="col-12 col-md-6">
              <mdb-input
                label="Cargo*"
                type="text"
                v-model="dataForm.cargo"
                required
                outline
              ></mdb-input>
            </div>

            <div class="col-12 col-md-6">
              <mdb-container>
                <mdb-select
                  label="Idioma de comunicación"
                  :color="'danger'"
                  v-model="idiomasOptions"
                  @getValue="(evt) => (dataForm.idioma = evt)"
                />
              </mdb-container>
            </div>

            <div class="col-12 col-md-6">
              <mdb-container>
                <mdb-input
                  label="Nombre de usuario"
                  :color="'danger'"
                  v-model="dataForm.username"
                />
              </mdb-container>
            </div>
          </div>

          <div class="row w-100 mt-2">
            <div class="col-12 spainhn-divider mb-2 text-start">
              <p class="fw-bold">Datos de la empresa</p>
            </div>

            <div class="col-12">
              <mdb-input
                label="Razón social*"
                type="text"
                name="razon-social"
                v-model="dataForm.razonSocial"
                required
                outline
                invalidFeedback="Campo obligatorio *"
              />
            </div>

            <div class="col-6">
              <mdb-input
                label="Nombre comercial*"
                type="text"
                name="nombre-comercial"
                v-model="dataForm.nombreComercial"
                required
                outline
                invalidFeedback="Campo obligatorio *"
              />
            </div>

            <div class="col-6">
              <mdb-input
                label="Cuenta bancaria*"
                type="text"
                name="cuenta-bancaria"
                v-model="dataForm.cuentaBancaria"
                required
                outline
                invalidFeedback="Campo obligatorio *"
              />
            </div>

            <div class="col-md-6 col-12">
              <mdb-container>
                <mdb-select
                  label="Tipo de identificación fiscal*"
                  type="text"
                  name="tipo-if"
                  color="danger"
                  v-model="identificacionOptions"
                  @getValue="(evt) => (dataForm.tipoIdentificacion = evt)"
                  required
                  outline
                  invalidFeedback="Campo obligatorio *"
                />
              </mdb-container>
            </div>

            <div class="col-md-6 col-12">
              <mdb-input
                label="Número de Identificación Fiscal*"
                v-model="dataForm.nif"
                required
                outline
              />
            </div>
          </div>

          <div class="row w-100 mt-2 gy-0">
            <div class="col-12 spainhn-divider mb-2 text-start">
              <p class="fw-bold">Ubicación de la empresa</p>
            </div>

            <div class="col-12">
              <mdb-input
                label="Dirección"
                type="text"
                v-model="dataForm.ubicacionEmpresa.direccion"
                required
                outline
                invalidFeedback="Proporcione una dirección válida"
              ></mdb-input>
            </div>

            <div class="col-12 col-md-6">
              <mdb-input
                label="Código postal"
                type="text"
                size="5"
                :customValidation="validation.zipCode.validated"
                :isValid="validation.zipCode.valid"
                required
                outline
                ref="zipCode"
                v-model="dataForm.ubicacionEmpresa.codigoPostal"
                @change="validate('zipCode')"
                invalidFeedback="Proporcione un Código postal válido"
              />
            </div>

            <div class="col-md-6 col-12">
              <!-- <mdb-select label="Comunida autónoma" v-model="comunidadesAutonomas" 
                                    @getValue="(evt) => dataForm.comunidadAutonoma = evt" color="danger"
                                    ref="comunidadSelect"
                                    required outline /> -->
              <mdb-input
                v-model="dataForm.ubicacionEmpresa.comunidadAutonoma"
                ref="comunidadSelect"
                required
                disabled
                outline
                label="Comunidad Autónoma"
              />
            </div>

            <div class="col-12">
              <div class="row">
                <div class="col-md-6 col-12">
                  <mdb-input
                    v-model="dataForm.ubicacionEmpresa.provincia"
                    ref="provinciaSelect"
                    required
                    disabled
                    outline
                    label="Provincia"
                  />
                </div>
                <div class="col-md-6 col-12">
                  <!-- <mdb-select label="Provincia" v-model="provincias" color="danger"
                                            @getValue="(evt) => dataForm.provincia = evt"
                                            ref="provinciaSelect"
                                            required outline /> -->
                  <mdb-input
                    v-model="dataForm.ubicacionEmpresa.municipio"
                    ref="municipioSelect"
                    required
                    disabled
                    outline
                    label="Municipio"
                    placeholder=" "
                  />
                </div>
              </div>
            </div>

            <div class="col-12 text-start mb-0">
              <small class="text-muted"
                >Coordenadas
                <span>
                  <font-awesome-icon
                    icon="fa-info-circle"
                    data-mdb-toggle="tooltip"
                    data-mdb-placement="right"
                    title="Puede encontrar esta información buscando el monumento en google maps"
                  >
                  </font-awesome-icon> </span
              ></small>
            </div>

            <GoogleMapV2
              placeholder-input="Introduce tu dirección primero"
              @markerAdded="setCoordinates"
            />

            <div class="col-md-6 col-12">
              <mdb-input
                label="latitud"
                type="text"
                v-model="dataForm.ubicacionEmpresa.coordenadas.lat"
                outline
                required
              />
            </div>
            <div class="col-md-6 col-12">
              <mdb-input
                label="longitud"
                type="text"
                v-model="dataForm.ubicacionEmpresa.coordenadas.lng"
                outline
                required
              />
            </div>
          </div>

          <div class="row w-100 mt-2 gy-0">
            <div class="col-12 spainhn-divider mb-2 text-start">
              <p class="fw-bold">Dirección de contacto</p>
            </div>

            <div class="col-12">
              <mdb-input
                label="Dirección"
                type="text"
                v-model="dataForm.direccionContacto"
                required
                outline
                invalidFeedback="Proporcione una dirección válida"
              ></mdb-input>
            </div>

            <div class="col-12 col-md-4">
              <mdb-input
                label="Código postal"
                type="text"
                size="5"
                required
                outline
                v-model="dataForm.codigoPostal"
                invalidFeedback="Proporcione un Código postal válido"
              />
            </div>

            <div class="col-12 col-md-8">
              <mdb-container>
                <mdb-select
                  search
                  placeholder="País"
                  validate
                  required
                  outline
                  v-model="paises"
                  @getValue="(evt) => (dataForm.paisContacto = evt)"
                  invalidFeedback="Campo obligatorio"
                  color="danger"
                />
              </mdb-container>
            </div>

            <div class="col-md-6 col-12">
              <mdb-input
                label="Provincia/Estado"
                v-model="dataForm.provinciaContacto"
                required
                outline
              />
            </div>
            <div class="col-md-6 col-12">
              <mdb-input
                label="Ciudad"
                v-model="dataForm.ciudadContacto"
                color="danger"
                required
                outline
              />
            </div>

            <div class="col-12 text-start">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="direccionFiscalSwitch"
                  v-model="direccionFiscalDiferente"
                />
                <label class="custom-control-label" for="direccionFiscalSwitch"
                  >Direccion Fiscal diferente</label
                >
              </div>
            </div>
          </div>

          <div
            class="row w-100 mt-2 gy-0"
            v-if="direccionFiscalDiferente"
          >
            <div class="col-12 spainhn-divider mb-2 text-start">
              <p class="fw-bold">Dirección fiscal</p>
            </div>

            <div class="col-12">
              <mdb-input
                label="Dirección"
                type="text"
                v-model="dataForm.direccionFiscal"
                name="direccion-fiscal"
                required
                outline
                invalidFeedback="Proporcione una dirección válida"
              ></mdb-input>
            </div>

            <div class="col-12 col-md-4">
              <mdb-input
                label="Código postal"
                type="text"
                size="5"
                name="codigo-postal"
                required
                outline
                v-model="dataForm.codigoPostalFiscal"
                invalidFeedback="Proporcione un Código postal válido"
              />
            </div>

            <div class="col-12 col-md-8">
              <mdb-container>
                <mdb-select
                  search
                  placeholder="País"
                  validate
                  required
                  outline
                  v-model="paises"
                  name="pais-fiscal"
                  @getValue="(evt) => (dataForm.paisFiscal = evt)"
                  color="danger"
                  invalidFeedback="Campo obligatorio"
                />
              </mdb-container>
            </div>

            <div class="col-md-6 col-12">
              <mdb-input
                label="Provincia/Estado"
                v-model="dataForm.provinciaFiscal"
                name="estado-fiscal"
                required
                outline
              />
            </div>
            <div class="col-md-6 col-12">
              <mdb-input
                label="Ciudad"
                v-model="dataForm.ciudadFiscal"
                name="ciudad-fiscal"
                required
                outline
              />
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-12 spainhn-divider mb-2 text-start">
              <p class="fw-bold">Terminos y condiciones</p>
            </div>

            <div class="col-12 text-start mt-2 my-auto">
              <mdb-input
                class="my-auto d-flex"
                type="checkbox"
                id="terminos-condiciones"
                name="terminos-condiciones"
                v-model="aceptoTerminos"
              >
                <p class="my-auto">
                  Acepto el
                  <span
                    ><a
                      href="https://spainheritagenetwork.com/privacidad-cookies"
                      >tratamiento de mis datos personales</a
                    ></span
                  >
                  para poder acceder al Módulo de Distribuidores de
                  Spainheritagenetwork*
                </p>
              </mdb-input>
            </div>
          </div>

          <mdb-btn
            color="indigo"
            :disabled="enviandoSolicitud || !aceptoTerminos"
            class="spainhn-button mb-2 mt-4"
            size="lg"
            type="submit"
          >
            Enviar solicitud
            <span v-bind:class="{ 'd-none': !enviandoSolicitud }">
              <b-spinner></b-spinner>
            </span>
          </mdb-btn>
        </form>
      </div>
    </div>

    <mdb-modal :show="welcomeMessageOn" centered size="lg">
      <mdb-modal-body>        
        <mdb-modal-title class="font-weight-bold border-bottom py-2">
          ¡BIENVENIDOS AL PACK EXPERIENCIAS!
        </mdb-modal-title>
        <div class="text-center">
          <p>
            Te damos la bienvenida a la sección del PACK EXPERIENCIAS de la plataforma de la Red de Patrimonio Histórico de España. 
          </p>
          <p>
            Si eres un guía turístico, informador turístico, gestor de grupos o gestor de un recurso turístico eres el PROMOTOR que buscamos y estás en el lugar correcto para subir tu propuesta turística y así formar parte de nuestra red de experiencias.
          </p>
          <p>
            Esta sección nace para facilitar al PROMOTOR la gestión y comercialización de su Tour guiado o paquete/ruta turística y complementar la oferta que ofrecen nuestros monumentos para proporcionar una experiencia única a nuestros visitantes.
          </p>
          <p>
            Conviértete en un destino más de la Red de patrimonio histórico de España y contribuye con tu EXPERIENCIA turística a una mejor difusión y conocimiento de nuestro rico patrimonio histórico y cultural. 
          </p>
          <p>
            Benefíciate de la multicanalidad e hiper conectividad de la red para una distribución más eficiente de tu oferta turística y ahorra tiempo y costes con la liquidación automática a los proveedores de tu tour o paquete turístico.
          </p>
        </div>

      </mdb-modal-body>

      <mdb-model-footer class="border-top py-1">
        <mdb-btn @click="() => welcomeMessageOn=false" color="danger">
          CONTINUAR
        </mdb-btn>
      </mdb-model-footer>

    </mdb-modal>

    <mdb-modal
      frame
      position="top"
      direction="top"
      :show="alertaModal"
      @close="alertaModal = false"
    >
      <mdb-modal-body class="text-center">
        <span>{{ mensaje }}</span>
        <mdb-btn color="primary" @click.native="alertaModal = false"
          >ACEPTAR</mdb-btn
        >
      </mdb-modal-body>
    </mdb-modal>
  </default-layout>
</template>

<script>
import data from "iso3166-2-db/countryList/dispute/UN/es";
import ApiService from "../../services/ApiService";
import GoogleMapV2 from "../../components/GoogleMapV2.vue";

export default {
  components: {
    GoogleMapV2,
  },
  data() {
    return {
      dataForm: {
        ubicacionEmpresa: {
          direccion: null,
          codigoPostal: null,
          provincia: null,
          provinciaCode: null,
          comunidadAutonoma: null,
          municipio: null,
          municipioCode: null,
          coordenadas: {
            lat: null,
            lng: null,
          },
        },
        direccionContacto: null,
        ciudadContacto: null,
        provinciaContacto: null,
        nombreContacto: null,
        telefonoContacto: null,
        emailContacto: null,
        codigoPostal: null,
        paisContacto: null,
        cargo: null,
        idioma: null,
        direccionFiscal: null,
        ciudadFiscal: null,
        provinciaFiscal: null,
        horarioContacto: null,
        paisFiscal: null,
        codigoPostalFiscal: null,
        tipoDeMercado: null,
        nif: null,
        tipoIdentificacion: null,
        nombreComercial: null,
        cuentaBancaria: null,
        razonSocial: null,
        espana: false,
        europa: false,
        america: false,
        asia: false,
        oriente: false,
        oceania: false,
        africa: false,
        username: null,
      },
      validation2: {
        zipCode: {
          valid: false,
          validated: false,
        },
        aceptoTerminos: {
          valid: true,
          validated: false,
        },
        comunidad: {
          valid: true,
          validated: false,
        },
        provincia: {
          valid: true,
          validated: false,
        },
      },
      aceptoTerminos: false,
      direccionFiscalDiferente: false,
      idiomasOptions: [
        { text: "Español", value: "Español" },
        { text: "Inglés", value: "Inglés" },
        { text: "Catalán", value: "Catalán" },
      ],
      identificacionOptions: [
        { text: "NIF", value: "NIF" },
        { text: "NIE", value: "NIE" },
        { text: "CIF", value: "CIF" },
        { text: "EU VAT", value: "EU VAT" },
        { text: "Otros", value: "Otros" },
      ],
      tiposDeMercado: [
        { text: "Grupos", value: "Grupos" },
        { text: "Individual", value: "Individual" },
        { text: "Todos", value: "Todos" },
      ],
      validation: {
        zipCode: {
          valid: false,
          validated: false,
        },
      },
      paises: [],
      enviandoSolicitud: false,
      alertaModal: false,
      mensaje: null,
      provincias: [],
      comunidadesAutonomas: [],
      welcomeMessageOn: false
    };
  },
  methods: {
    setCoordinates(evt) {
      console.log(evt);
      this.dataForm.ubicacionEmpresa.coordenadas.lat = evt.lat;
      this.dataForm.ubicacionEmpresa.coordenadas.lng = evt.lng;
    },
    goToHome() {
      let $vm = this;
      $vm.$router.push("/");
    },
    async validarRegistro(evt) {
      evt.target.classList.add("was-validated");

      this.enviandoSolicitud = true;
      const response = await ApiService.sendPrestadorRequest(this.dataForm);

      const mensaje = response.data.codigo;
      const error = response.data.error;
      if (error) {
        this.mensaje = error;
      } else {
        this.mensaje = `Su solicitud fue recibida con éxito, con el código: ${mensaje}. Le agradecemos su interes, pronto nos comunicaremos con usted`;
      }
      this.alertaModal = true;
      this.enviandoSolicitud = false;
    },
    validarCodigoPostal(codigo) {
      // Convertimos el string que recibimos a un número, para poder corroborar que el
      // input sea un número válido
      let codeToNumber = parseInt(codigo);

      // Si el resultado nos indica que es falso, entonces se introdujeron letras, no solo números
      let isNumber = !isNaN(codeToNumber);

      let isValid = false;

      if (isNumber) {
        if (codeToNumber >= 1000) {
          isValid = true;
        }
      }
      return isValid;
    },
    clearCodigoPostal() {
      this.dataForm.ubicacionEmpresa.provincia = null;
      this.dataForm.ubicacionEmpresa.comunidadAutonoma = null;
    },
    async validate(key, value) {
      if (key === "zipCode") {
        console.log("Aquí");
        this.dataForm.ubicacionEmpresa.codigoPostal =
          this.dataForm.ubicacionEmpresa.codigoPostal.trim();
        if (this.dataForm.ubicacionEmpresa.codigoPostal.length == 5) {
          let isValid = this.validarCodigoPostal(
            this.dataForm.ubicacionEmpresa.codigoPostal
          );
          if (isValid) {
            await this.setComunityAndProvince(
              this.dataForm.ubicacionEmpresa.codigoPostal
            );
            this.validation["zipCode"].valid = true;
          } else {
            this.validation["zipCode"].valid = true;
            this.clearCodigoPostal();
          }
        } else {
          this.validation["zipCode"].valid = false;
        }
        this.validation["zipCode"].validated = true;
      } else if (key === "aceptoTerminos") {
        if (this.aceptoTerminos != true) {
          this.validation["aceptoTerminos"].valid = false;
        } else {
          this.validation["aceptoTerminos"].valid = true;
        }
        this.validation["aceptoTerminos"].validated = true;
      } else if (key == "comunidad") {
        if (
          this.dataForm.ubicacionEmpresa.comunidadAutonoma != null &&
          this.dataForm.ubicacionEmpresa.comunidadAutonoma != ""
        ) {
          this.validation["comunidad"].valid = true;
        } else {
          this.validation["comunidad"].valid = false;
        }
        this.validation["comunidad"].validated = true;
      } else if (key == "provincia") {
        if (
          this.dataForm.ubicacionEmpresa.provincia != null &&
          this.dataForm.ubicacionEmpresa.provincia != ""
        ) {
          this.validation["provincia"].valid = true;
        } else {
          this.validation["provincia"].valid = false;
        }
        this.validation["provincia"].validated = true;
      }
    },
    async setComunityAndProvince(codigoPostal) {
      let comunityCode = codigoPostal.substr(0, 2);

      try {
        const municipioResult = await ApiService.getMunicpio(
          codigoPostal.trim()
        );
        const municipio = municipioResult.data;
        this.dataForm.ubicacionEmpresa.municipio = municipio.municipio_nombre;
        this.dataForm.ubicacionEmpresa.municipioCode = municipio.municipio_id;
      } catch (err) {
        console.log(err);
      }

      this.provincias.forEach((provincia) => {
        if (provincia.value == comunityCode) {
          provincia.selected = true;
          // this.dataForm.provinciaCliente = provincia.text;
          this.dataForm.ubicacionEmpresa.provinciaCode = provincia.value;
          this.dataForm.ubicacionEmpresa.provincia = provincia.text;
          this.dataForm.ubicacionEmpresa.comunidadAutonoma =
            provincia.comunidad;

          this.comunidadesAutonomas.forEach((comunidad) => {
            if (comunidad.value === provincia.comunidad) {
              comunidad.selected = true;
            } else {
              comunidad.selected = false;
            }
          });
        } else {
          provincia.selected = false;
        }
      });
    },
  },
  mounted() {
    // console.log(Object.values(data))
    const listaDePaises = Object.values(data);

    this.comunidadesAutonomas = [...this.$store.getters.comunidadesAutonomas];
    this.provincias = [...this.$store.getters.provincias];

    this.paises = listaDePaises.map((paisData) => {
      let obj = {
        value: paisData.iso,
        text: paisData.name,
      };
      return obj;
    });

    this.welcomeMessageOn = true;
  },
};
</script>
