var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "default-layout",
    [
      _c(
        "div",
        {
          staticClass: "spainhn-solid-red-background d-flex",
          staticStyle: { "min-height": "5vh", width: "100%" },
        },
        [
          _c("div", { staticClass: "d-flex ps-5 my-auto container" }, [
            _c(
              "p",
              {
                staticClass: "my-auto pb-0 mb-0 spainhn-small-text",
                attrs: { role: "button" },
                on: { click: _vm.goToHome },
              },
              [_vm._v(" Spain Heritage Network >> ")]
            ),
            _c(
              "p",
              { staticClass: "my-auto pb-0 mb-0 ms-2 spainhn-small-text" },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("default.SERVICE_PROVIDERS_REGISTER")) +
                    " "
                ),
              ]
            ),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "container-fluid px-md-1 px-lg-4 px-1 py-5",
          style: {
            padding: "0 0",
            backgroundImage: `url(${require("@/assets/home-bg.jpg")})`,
            backgroundSize: "cover",
            "background-repeat": "no-repeat",
            "background-position": "right",
            "min-height": "80vh",
          },
        },
        [
          _c(
            "div",
            { staticClass: "container bg-white shadow px-sm-0 px-md-5 pt-3" },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("p", { staticClass: "spainhn-subtitle" }, [
                    _vm._v("Registro como promotor de servicios"),
                  ]),
                ]),
              ]),
              _c(
                "form",
                {
                  staticClass: "pt-1 pb-5 mb-5",
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.validarRegistro.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 spainhn-divider mb-2 text-start" },
                      [
                        _c("p", { staticClass: "fw-bold" }, [
                          _vm._v("Datos de usuario"),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-6" },
                      [
                        _c("mdb-input", {
                          attrs: {
                            label: "Persona de contacto*",
                            type: "text",
                            name: "persona-contacto",
                            required: "",
                            outline: "",
                            invalidFeedback: "Campo obligatorio *",
                          },
                          model: {
                            value: _vm.dataForm.nombreContacto,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "nombreContacto", $$v)
                            },
                            expression: "dataForm.nombreContacto",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6 col-12" },
                      [
                        _c("mdb-input", {
                          attrs: {
                            label: "Email de usuario *",
                            type: "email",
                            name: "email-usuario",
                            required: "",
                            outline: "",
                            invalidFeedback: "Campo obligatorio *",
                          },
                          model: {
                            value: _vm.dataForm.emailContacto,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "emailContacto", $$v)
                            },
                            expression: "dataForm.emailContacto",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-6" },
                      [
                        _c("mdb-input", {
                          attrs: {
                            label: "Teléfono*",
                            type: "tel",
                            required: "",
                            outline: "",
                          },
                          model: {
                            value: _vm.dataForm.telefonoContacto,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "telefonoContacto", $$v)
                            },
                            expression: "dataForm.telefonoContacto",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-6" },
                      [
                        _c("mdb-input", {
                          attrs: {
                            label: "Cargo*",
                            type: "text",
                            required: "",
                            outline: "",
                          },
                          model: {
                            value: _vm.dataForm.cargo,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "cargo", $$v)
                            },
                            expression: "dataForm.cargo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-6" },
                      [
                        _c(
                          "mdb-container",
                          [
                            _c("mdb-select", {
                              attrs: {
                                label: "Idioma de comunicación",
                                color: "danger",
                              },
                              on: {
                                getValue: (evt) => (_vm.dataForm.idioma = evt),
                              },
                              model: {
                                value: _vm.idiomasOptions,
                                callback: function ($$v) {
                                  _vm.idiomasOptions = $$v
                                },
                                expression: "idiomasOptions",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-6" },
                      [
                        _c(
                          "mdb-container",
                          [
                            _c("mdb-input", {
                              attrs: {
                                label: "Nombre de usuario",
                                color: "danger",
                              },
                              model: {
                                value: _vm.dataForm.username,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "username", $$v)
                                },
                                expression: "dataForm.username",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "row w-100 mt-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 spainhn-divider mb-2 text-start" },
                      [
                        _c("p", { staticClass: "fw-bold" }, [
                          _vm._v("Datos de la empresa"),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("mdb-input", {
                          attrs: {
                            label: "Razón social*",
                            type: "text",
                            name: "razon-social",
                            required: "",
                            outline: "",
                            invalidFeedback: "Campo obligatorio *",
                          },
                          model: {
                            value: _vm.dataForm.razonSocial,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "razonSocial", $$v)
                            },
                            expression: "dataForm.razonSocial",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("mdb-input", {
                          attrs: {
                            label: "Nombre comercial*",
                            type: "text",
                            name: "nombre-comercial",
                            required: "",
                            outline: "",
                            invalidFeedback: "Campo obligatorio *",
                          },
                          model: {
                            value: _vm.dataForm.nombreComercial,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "nombreComercial", $$v)
                            },
                            expression: "dataForm.nombreComercial",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("mdb-input", {
                          attrs: {
                            label: "Cuenta bancaria*",
                            type: "text",
                            name: "cuenta-bancaria",
                            required: "",
                            outline: "",
                            invalidFeedback: "Campo obligatorio *",
                          },
                          model: {
                            value: _vm.dataForm.cuentaBancaria,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "cuentaBancaria", $$v)
                            },
                            expression: "dataForm.cuentaBancaria",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6 col-12" },
                      [
                        _c(
                          "mdb-container",
                          [
                            _c("mdb-select", {
                              attrs: {
                                label: "Tipo de identificación fiscal*",
                                type: "text",
                                name: "tipo-if",
                                color: "danger",
                                required: "",
                                outline: "",
                                invalidFeedback: "Campo obligatorio *",
                              },
                              on: {
                                getValue: (evt) =>
                                  (_vm.dataForm.tipoIdentificacion = evt),
                              },
                              model: {
                                value: _vm.identificacionOptions,
                                callback: function ($$v) {
                                  _vm.identificacionOptions = $$v
                                },
                                expression: "identificacionOptions",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6 col-12" },
                      [
                        _c("mdb-input", {
                          attrs: {
                            label: "Número de Identificación Fiscal*",
                            required: "",
                            outline: "",
                          },
                          model: {
                            value: _vm.dataForm.nif,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "nif", $$v)
                            },
                            expression: "dataForm.nif",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "row w-100 mt-2 gy-0" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "col-12 spainhn-divider mb-2 text-start",
                        },
                        [
                          _c("p", { staticClass: "fw-bold" }, [
                            _vm._v("Ubicación de la empresa"),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("mdb-input", {
                            attrs: {
                              label: "Dirección",
                              type: "text",
                              required: "",
                              outline: "",
                              invalidFeedback:
                                "Proporcione una dirección válida",
                            },
                            model: {
                              value: _vm.dataForm.ubicacionEmpresa.direccion,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.dataForm.ubicacionEmpresa,
                                  "direccion",
                                  $$v
                                )
                              },
                              expression: "dataForm.ubicacionEmpresa.direccion",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12 col-md-6" },
                        [
                          _c("mdb-input", {
                            ref: "zipCode",
                            attrs: {
                              label: "Código postal",
                              type: "text",
                              size: "5",
                              customValidation:
                                _vm.validation.zipCode.validated,
                              isValid: _vm.validation.zipCode.valid,
                              required: "",
                              outline: "",
                              invalidFeedback:
                                "Proporcione un Código postal válido",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.validate("zipCode")
                              },
                            },
                            model: {
                              value: _vm.dataForm.ubicacionEmpresa.codigoPostal,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.dataForm.ubicacionEmpresa,
                                  "codigoPostal",
                                  $$v
                                )
                              },
                              expression:
                                "dataForm.ubicacionEmpresa.codigoPostal",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6 col-12" },
                        [
                          _c("mdb-input", {
                            ref: "comunidadSelect",
                            attrs: {
                              required: "",
                              disabled: "",
                              outline: "",
                              label: "Comunidad Autónoma",
                            },
                            model: {
                              value:
                                _vm.dataForm.ubicacionEmpresa.comunidadAutonoma,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.dataForm.ubicacionEmpresa,
                                  "comunidadAutonoma",
                                  $$v
                                )
                              },
                              expression:
                                "dataForm.ubicacionEmpresa.comunidadAutonoma",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "col-12" }, [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-6 col-12" },
                            [
                              _c("mdb-input", {
                                ref: "provinciaSelect",
                                attrs: {
                                  required: "",
                                  disabled: "",
                                  outline: "",
                                  label: "Provincia",
                                },
                                model: {
                                  value:
                                    _vm.dataForm.ubicacionEmpresa.provincia,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataForm.ubicacionEmpresa,
                                      "provincia",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "dataForm.ubicacionEmpresa.provincia",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-6 col-12" },
                            [
                              _c("mdb-input", {
                                ref: "municipioSelect",
                                attrs: {
                                  required: "",
                                  disabled: "",
                                  outline: "",
                                  label: "Municipio",
                                  placeholder: " ",
                                },
                                model: {
                                  value:
                                    _vm.dataForm.ubicacionEmpresa.municipio,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dataForm.ubicacionEmpresa,
                                      "municipio",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "dataForm.ubicacionEmpresa.municipio",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-12 text-start mb-0" }, [
                        _c("small", { staticClass: "text-muted" }, [
                          _vm._v("Coordenadas "),
                          _c(
                            "span",
                            [
                              _c("font-awesome-icon", {
                                attrs: {
                                  icon: "fa-info-circle",
                                  "data-mdb-toggle": "tooltip",
                                  "data-mdb-placement": "right",
                                  title:
                                    "Puede encontrar esta información buscando el monumento en google maps",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _c("GoogleMapV2", {
                        attrs: {
                          "placeholder-input": "Introduce tu dirección primero",
                        },
                        on: { markerAdded: _vm.setCoordinates },
                      }),
                      _c(
                        "div",
                        { staticClass: "col-md-6 col-12" },
                        [
                          _c("mdb-input", {
                            attrs: {
                              label: "latitud",
                              type: "text",
                              outline: "",
                              required: "",
                            },
                            model: {
                              value:
                                _vm.dataForm.ubicacionEmpresa.coordenadas.lat,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.dataForm.ubicacionEmpresa.coordenadas,
                                  "lat",
                                  $$v
                                )
                              },
                              expression:
                                "dataForm.ubicacionEmpresa.coordenadas.lat",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6 col-12" },
                        [
                          _c("mdb-input", {
                            attrs: {
                              label: "longitud",
                              type: "text",
                              outline: "",
                              required: "",
                            },
                            model: {
                              value:
                                _vm.dataForm.ubicacionEmpresa.coordenadas.lng,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.dataForm.ubicacionEmpresa.coordenadas,
                                  "lng",
                                  $$v
                                )
                              },
                              expression:
                                "dataForm.ubicacionEmpresa.coordenadas.lng",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "row w-100 mt-2 gy-0" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 spainhn-divider mb-2 text-start" },
                      [
                        _c("p", { staticClass: "fw-bold" }, [
                          _vm._v("Dirección de contacto"),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("mdb-input", {
                          attrs: {
                            label: "Dirección",
                            type: "text",
                            required: "",
                            outline: "",
                            invalidFeedback: "Proporcione una dirección válida",
                          },
                          model: {
                            value: _vm.dataForm.direccionContacto,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "direccionContacto", $$v)
                            },
                            expression: "dataForm.direccionContacto",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-4" },
                      [
                        _c("mdb-input", {
                          attrs: {
                            label: "Código postal",
                            type: "text",
                            size: "5",
                            required: "",
                            outline: "",
                            invalidFeedback:
                              "Proporcione un Código postal válido",
                          },
                          model: {
                            value: _vm.dataForm.codigoPostal,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "codigoPostal", $$v)
                            },
                            expression: "dataForm.codigoPostal",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-8" },
                      [
                        _c(
                          "mdb-container",
                          [
                            _c("mdb-select", {
                              attrs: {
                                search: "",
                                placeholder: "País",
                                validate: "",
                                required: "",
                                outline: "",
                                invalidFeedback: "Campo obligatorio",
                                color: "danger",
                              },
                              on: {
                                getValue: (evt) =>
                                  (_vm.dataForm.paisContacto = evt),
                              },
                              model: {
                                value: _vm.paises,
                                callback: function ($$v) {
                                  _vm.paises = $$v
                                },
                                expression: "paises",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6 col-12" },
                      [
                        _c("mdb-input", {
                          attrs: {
                            label: "Provincia/Estado",
                            required: "",
                            outline: "",
                          },
                          model: {
                            value: _vm.dataForm.provinciaContacto,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "provinciaContacto", $$v)
                            },
                            expression: "dataForm.provinciaContacto",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6 col-12" },
                      [
                        _c("mdb-input", {
                          attrs: {
                            label: "Ciudad",
                            color: "danger",
                            required: "",
                            outline: "",
                          },
                          model: {
                            value: _vm.dataForm.ciudadContacto,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "ciudadContacto", $$v)
                            },
                            expression: "dataForm.ciudadContacto",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "col-12 text-start" }, [
                      _c(
                        "div",
                        { staticClass: "custom-control custom-switch" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.direccionFiscalDiferente,
                                expression: "direccionFiscalDiferente",
                              },
                            ],
                            staticClass: "custom-control-input",
                            attrs: {
                              type: "checkbox",
                              id: "direccionFiscalSwitch",
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.direccionFiscalDiferente
                              )
                                ? _vm._i(_vm.direccionFiscalDiferente, null) >
                                  -1
                                : _vm.direccionFiscalDiferente,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.direccionFiscalDiferente,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.direccionFiscalDiferente =
                                        $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.direccionFiscalDiferente = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.direccionFiscalDiferente = $$c
                                }
                              },
                            },
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "direccionFiscalSwitch" },
                            },
                            [_vm._v("Direccion Fiscal diferente")]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm.direccionFiscalDiferente
                    ? _c("div", { staticClass: "row w-100 mt-2 gy-0" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-12 spainhn-divider mb-2 text-start",
                          },
                          [
                            _c("p", { staticClass: "fw-bold" }, [
                              _vm._v("Dirección fiscal"),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c("mdb-input", {
                              attrs: {
                                label: "Dirección",
                                type: "text",
                                name: "direccion-fiscal",
                                required: "",
                                outline: "",
                                invalidFeedback:
                                  "Proporcione una dirección válida",
                              },
                              model: {
                                value: _vm.dataForm.direccionFiscal,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "direccionFiscal", $$v)
                                },
                                expression: "dataForm.direccionFiscal",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-12 col-md-4" },
                          [
                            _c("mdb-input", {
                              attrs: {
                                label: "Código postal",
                                type: "text",
                                size: "5",
                                name: "codigo-postal",
                                required: "",
                                outline: "",
                                invalidFeedback:
                                  "Proporcione un Código postal válido",
                              },
                              model: {
                                value: _vm.dataForm.codigoPostalFiscal,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.dataForm,
                                    "codigoPostalFiscal",
                                    $$v
                                  )
                                },
                                expression: "dataForm.codigoPostalFiscal",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-12 col-md-8" },
                          [
                            _c(
                              "mdb-container",
                              [
                                _c("mdb-select", {
                                  attrs: {
                                    search: "",
                                    placeholder: "País",
                                    validate: "",
                                    required: "",
                                    outline: "",
                                    name: "pais-fiscal",
                                    color: "danger",
                                    invalidFeedback: "Campo obligatorio",
                                  },
                                  on: {
                                    getValue: (evt) =>
                                      (_vm.dataForm.paisFiscal = evt),
                                  },
                                  model: {
                                    value: _vm.paises,
                                    callback: function ($$v) {
                                      _vm.paises = $$v
                                    },
                                    expression: "paises",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6 col-12" },
                          [
                            _c("mdb-input", {
                              attrs: {
                                label: "Provincia/Estado",
                                name: "estado-fiscal",
                                required: "",
                                outline: "",
                              },
                              model: {
                                value: _vm.dataForm.provinciaFiscal,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "provinciaFiscal", $$v)
                                },
                                expression: "dataForm.provinciaFiscal",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6 col-12" },
                          [
                            _c("mdb-input", {
                              attrs: {
                                label: "Ciudad",
                                name: "ciudad-fiscal",
                                required: "",
                                outline: "",
                              },
                              model: {
                                value: _vm.dataForm.ciudadFiscal,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "ciudadFiscal", $$v)
                                },
                                expression: "dataForm.ciudadFiscal",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "row mt-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 spainhn-divider mb-2 text-start" },
                      [
                        _c("p", { staticClass: "fw-bold" }, [
                          _vm._v("Terminos y condiciones"),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12 text-start mt-2 my-auto" },
                      [
                        _c(
                          "mdb-input",
                          {
                            staticClass: "my-auto d-flex",
                            attrs: {
                              type: "checkbox",
                              id: "terminos-condiciones",
                              name: "terminos-condiciones",
                            },
                            model: {
                              value: _vm.aceptoTerminos,
                              callback: function ($$v) {
                                _vm.aceptoTerminos = $$v
                              },
                              expression: "aceptoTerminos",
                            },
                          },
                          [
                            _c("p", { staticClass: "my-auto" }, [
                              _vm._v(" Acepto el "),
                              _c("span", [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: "https://spainheritagenetwork.com/privacidad-cookies",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "tratamiento de mis datos personales"
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(
                                " para poder acceder al Módulo de Distribuidores de Spainheritagenetwork* "
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "mdb-btn",
                    {
                      staticClass: "spainhn-button mb-2 mt-4",
                      attrs: {
                        color: "indigo",
                        disabled: _vm.enviandoSolicitud || !_vm.aceptoTerminos,
                        size: "lg",
                        type: "submit",
                      },
                    },
                    [
                      _vm._v(" Enviar solicitud "),
                      _c(
                        "span",
                        { class: { "d-none": !_vm.enviandoSolicitud } },
                        [_c("b-spinner")],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c(
        "mdb-modal",
        { attrs: { show: _vm.welcomeMessageOn, centered: "", size: "lg" } },
        [
          _c(
            "mdb-modal-body",
            [
              _c(
                "mdb-modal-title",
                { staticClass: "font-weight-bold border-bottom py-2" },
                [_vm._v(" ¡BIENVENIDOS AL PACK EXPERIENCIAS! ")]
              ),
              _c("div", { staticClass: "text-center" }, [
                _c("p", [
                  _vm._v(
                    " Te damos la bienvenida a la sección del PACK EXPERIENCIAS de la plataforma de la Red de Patrimonio Histórico de España. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " Si eres un guía turístico, informador turístico, gestor de grupos o gestor de un recurso turístico eres el PROMOTOR que buscamos y estás en el lugar correcto para subir tu propuesta turística y así formar parte de nuestra red de experiencias. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " Esta sección nace para facilitar al PROMOTOR la gestión y comercialización de su Tour guiado o paquete/ruta turística y complementar la oferta que ofrecen nuestros monumentos para proporcionar una experiencia única a nuestros visitantes. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " Conviértete en un destino más de la Red de patrimonio histórico de España y contribuye con tu EXPERIENCIA turística a una mejor difusión y conocimiento de nuestro rico patrimonio histórico y cultural. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " Benefíciate de la multicanalidad e hiper conectividad de la red para una distribución más eficiente de tu oferta turística y ahorra tiempo y costes con la liquidación automática a los proveedores de tu tour o paquete turístico. "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "mdb-model-footer",
            { staticClass: "border-top py-1" },
            [
              _c(
                "mdb-btn",
                {
                  attrs: { color: "danger" },
                  on: { click: () => (_vm.welcomeMessageOn = false) },
                },
                [_vm._v(" CONTINUAR ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "mdb-modal",
        {
          attrs: {
            frame: "",
            position: "top",
            direction: "top",
            show: _vm.alertaModal,
          },
          on: {
            close: function ($event) {
              _vm.alertaModal = false
            },
          },
        },
        [
          _c(
            "mdb-modal-body",
            { staticClass: "text-center" },
            [
              _c("span", [_vm._v(_vm._s(_vm.mensaje))]),
              _c(
                "mdb-btn",
                {
                  attrs: { color: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      _vm.alertaModal = false
                    },
                  },
                },
                [_vm._v("ACEPTAR")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }