<template>
    <div>
      <div>
        <h5>{{ title }}</h5>
      </div>
      <br>
      <mdb-container>
        <GmapAutocomplete class="mb-4"
            style="width: 100%"
            @place_changed="setPlace"
            :placeholder="placeholderInput"
        />

        <GmapMap
            style='width:100%; height: 400px;'
            :center='center'
            :zoom='zoom'
            >
            <GmapMarker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                :draggable="true"
                @click="center=m.position"
                @dragend="( evt ) => setCoordinates(evt)"
            />
        </GmapMap>

      </mdb-container>

    </div>
  </template>
  
  <script>
  export default {
    name: 'GoogleMapV2',
    props:{
        title: {type: String, required: false, default: 'Busca la dirección exacta con la ayuda del mapa'},
        placeholderInput: {type: String, required: false}
    },
    data(){
        return{
            center: { lat: 40.463667 , lng: -3.74922 },
            currentPlace: null,
            markers: [],
            places: [],
            zoom: 6,
        }
    },
    methods:{
        setPlace(place) {
            this.currentPlace = place;
            if (this.currentPlace) {
                this.markers = [];
                const marker = {
                    lat: this.currentPlace.geometry.location.lat(),
                    lng: this.currentPlace.geometry.location.lng(),
                };
                this.markers.push({ position: marker });
                this.places.push(this.currentPlace);
                this.center = marker;
                this.currentPlace = null;
                this.zoom = 15;

                this.$emit( 'markerAdded', marker );
            }
        },
        addMarker() {
            if (this.currentPlace) {

                this.markers = [];

                const marker = {
                    lat: this.currentPlace.geometry.location.lat(),
                    lng: this.currentPlace.geometry.location.lng(),
                };

                this.markers.push({ position: marker });
                this.places.push(this.currentPlace);
                this.center = marker;
                this.currentPlace = null;
                this.zoom = 10;
            }
        },
        setCoordinates( evt ){
            const marker = {
                lat: evt.latLng.lat(),
                lng: evt.latLng.lng()
            }
            this.$emit( 'markerAdded', marker );
        }
    }
  }
  </script>